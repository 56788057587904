.page-camera {
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  height: -webkit-fill-available;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  overflow: hidden;
}

.stream {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.c {
  position: absolute;
  top: 0;
  left: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.btn-close {
  position: fixed;
  top: 20px;
  right: 10px;
  width: 60px;
  height: 60px;
  z-index: 4;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#292F43, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 50%;
  }
}

.btn-camera {
  position: fixed;
  bottom: 6%;
  left: 50%;
  width: 130px;
  transform: translate(-50%, 0);
  opacity: 0;
  visibility: hidden;
  // transition: opacity 500ms 500ms, visibility 500ms 500ms;
  transition: opacity 500ms 0ms, visibility 500ms 0ms;
  user-select: none;
  z-index: 3;

  &.is-visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 500ms 0ms, visibility 500ms 0ms;
  }

  a {
    position: relative;
    // pointer-events: none;
    z-index: 1;
  }
}

.frame {
  position: fixed;
  top: 17%;
  left: 50%;
  width: 84%;
  height: 67%;
  pointer-events: none;
  opacity: 0;
  transform: translate(-50%, 0);
  user-select: none;
  z-index: 3;

  img {
    width: 100%;
    height: 100%;
  }

  &.is-visible {
    opacity: 1;
    animation: frameAnim 1000ms linear infinite forwards;
  }

  &.is-fixed {
    opacity: 1;
    animation: none;
  }
}

@keyframes frameAnim {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.guide {
  position: fixed;
  left: 50%;
  top: 5%;
  max-width: 220px;
  width: 100%;
  transform: translate(-50%, 0);
  z-index: 2;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 30px;
    padding: 5px 0;
    border-radius: 34px;
    color: #152645;
    background-color: rgba(255, 255, 255, 1);

    &--first {
      background-color: rgba(9, 17, 44, 0.75);
      color: #fff;
    }
  }

  p {
    display: block;
    font-size: 15px;
    white-space: pre;
    line-height: 1.3;
  }
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 3;

  .icon {
    position: relative;
    width: 64px;
    transform: translate(-4px, 0);

    img {

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }

      &:nth-child(2) {
        animation: stoneAnim 2000ms infinite forwards;
      }

      &:nth-child(3) {
        animation: stoneAnim 2000ms 250ms infinite forwards;
      }

      &:nth-child(4) {
        animation: stoneAnim 2000ms 500ms infinite forwards;
      }
    }
  }

  .text {
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin: 24px 0 0 0;
  }

  .progress {
    max-width: 240px;
    width: 100%;
    height: 3px;
    margin: 36px 0 0 0;
    overflow: hidden;

    span {
      display: block;
      width: 100%;
      height: 100%;
      background-color: #fff;
    }
  }
}

@keyframes stoneAnim {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.warning-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 1);
  z-index: 4;

  .image {
    max-width: 64px;
    width: 100%;
    margin: 0 auto;
    transform: translate(-13%, 0);
  }

  .text {
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin: 24px 0 0 0;
  }

  &.show-pc {
    display: none;

    @media (min-width: 1024px) and (orientation: landscape) {
      display: flex;
    }
  }
}