@import '../base/vars';

.page-index {
  padding: 44px 0;
}

.logo {
  text-align: center;
}

.btnwrap {
  margin: 130px 0 0 0;
}

.btn {
  max-width: 350px;
  width: 100%;
  height: 100px;
  margin: 48px auto 0 auto;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: bold;
    background-color: color(accent);
    border-radius: 10px;
  }
}

.btn-group {
  margin: 45px 0 0 0;
  text-align: center;

  a {
    font-size: 16px;
    text-align: center;
    color: #fff;
    text-decoration: underline;
  }
}

.btn-promo {
  a {
    background-color: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}