@import '../base/vars';

.page-individual,
.page-group {
  padding: 44px 0;
  overflow-x: hidden;
}

.logo {
  text-align: center;
}

.intro {
  text-align: center;
  padding: 50px 0 0 0;

  &__lead {
    font-weight: 700;
    text-align: center;
    margin: 0 0 60px 0;
    line-height: 1.8;
  }

  &__title {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8;
  }

  &__text {
    font-size: 19px;
    font-weight: 700;
    text-align: center;
    color: #ffff00;
    line-height: 1.8;
  }

  &__notice {
    text-align: center;
    font-size: 13px;
    line-height: 1.8;
  }
}

.main {
  padding: 80px 0 0 0;

  &__container {
    @include md {
      padding: 0 !important;
    }
  }
}

.block {
  padding: 32px 80px 36px 80px;
  background-color: color(primary-light);
  border-radius: 10px;
  @include md {
    padding: 32px 20px 36px 20px;
  }

  &__head {
    position: relative;
    text-align: center;
    padding: 0 0 36px 0;

    &::after {
      content: "";
      position: absolute;
      left: -80px;
      bottom: 0;
      width: calc(100% + 160px);
      height: 4px;
      background-color: color(primary);
    }

    p {
      font-size: 22px;
      font-weight: 700;
      line-height: 1.8;
    }
  }

  &__main {
    padding: 50px 0 0 0;
  }

  &__notice {
    margin: 80px 0 0 0;
    @include md {
      margin: 60px 0 0 0;
    }

    small {
      font-size: 14px;
      @include md {
        font-size: 12px;
      }
    }
  }
}

.steps {
  $this: &;
  max-width: 620px;
  width: 100%;
  margin: 0 auto;

  &__item {
    + #{$this}__item {
      margin: 80px 0 0 0;
      @include md {
        margin: 60px 0 0 0;
      }
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    @include md {
      font-size: 21px;
    }

    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 8px 0 0;
      width: 34px;
      height: 34px;
      font-size: 18px;
      font-weight: 800;
      color: color(primary);
      background-color: #fff;
      border-radius: 50%;
    }
  }

  &__text {
    text-align: center;
    margin: 24px 0 0 0;
    @include md {
      font-size: 14px;
    }
  }

  &__qr {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 45px;
    margin: 32px 0 0 0;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    @include md {
      display: none;
    }

    figure {
      width: 150px;
    }

    span {
      font-size: 18px;
      font-weight: 500;
      margin: 0 0 0 36px;
      line-height: 1.7;
    }
  }

  &__btnwrap {
    display: none;
    @include md {
      display: block;
    }
  }

  &__btn {
    max-width: 350px;
    width: 100%;
    height: 70px;
    margin: 24px auto 0 auto;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 100%;
      font-size: 18px;
      font-weight: 700;
      color: color(text);
      background-color: color(accent);
      border-radius: 10px;
    }
  }

  &__image {
    width: 100%;
    margin: 30px auto 0 auto;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    @include md {
      max-width: 480px;
      height: auto;
      background-color: transparent;
    }
  }
}