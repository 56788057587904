.page-camera {
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  height: -webkit-fill-available;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  overflow: hidden;
}

.stream {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.c {
  position: absolute;
  top: 0;
  left: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.btn-close {
  position: fixed;
  top: 20px;
  right: 10px;
  width: 60px;
  height: 60px;
  z-index: 4;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#292F43, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 50%;
  }
}

.overlay-success {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;

  .content {
    text-align: center;
  }

  .image {
    width: 64px;
    margin: 0 auto;

    img {
      transform: translate(-4px, 0);
    }
  }

  .text {
    margin: 24px 0 0 0;

    p {
      font-size: 20px;
      color: #fff;
    }
  }
}