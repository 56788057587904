* {
  box-sizing: border-box;
}

html {
  overscroll-behavior-y: none;
}

body {
  position: relative;
  color: color(text);
  font-size: 20px;
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  background-color: color(primary);
  overflow-x: hidden;
  z-index: 1;

  &::-webkit-scrollbar {
    // display: none;
  }

  &.no-scroll {
    overflow-y: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: color(text);
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-weight: 400;
}

figure {
  margin: 0;
}

.sp-hide {
  display: block;
  @include md {
    display: none;
  }
}

.sp-show {
  display: none;
  @include md {
    display: block;
  }
}

.app {
  position: relative;
  z-index: 1;
}


.container {
  max-width: 1024px;
  width: 100%;
  padding: 0 60px;
  margin: 0 auto;
  @include md {
    padding: 0 20px;
  }
}

