.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-y: auto;
  z-index: 5;

  .container {
    padding: 40px 20px;
  }

  .btn-close {
    position: fixed;
    top: 20px;
    right: 10px;
    width: 60px;
    height: 60px;
    z-index: 4;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#292F43, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 50%;
    }
  }
}

.detail {
  &__img {
    max-width: 500px;
    min-height: 170px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  &__desc {
    margin: 36px 0 0 0;

    p {
      font-size: 20px;
      color: #fff;
      text-align: center;
      line-height: 1.8;
      white-space: pre;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 350px;
    width: 100%;
    margin: 36px auto 0 auto;
    padding: 18px 24px 21px 24px;
    background-color: rgba(#152645, 0.6);
    border-radius: 10px;

    &-title {
      display: flex;
      align-items: center;

      img {
        width: 55px;
      }

      p {
        display: inline-block;
        width: calc(100% - 55px);
        margin: 0 0 0 12px;
        font-size: 21px;
        color: #fff;
        line-height: 1.5;
      }
    }

    &-text {
      margin: 10px 0 0 0;
      text-align: center;

      p {
        font-size: 16px;
        color: #fff;
        line-height: 1.6;
      }
    }
  }
}